<template>
  <BaseCard :base-card="true" class="color-picker">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto d-flex align-items-center">
        <FormulateInput
          class="d-inline m-0"
          type="color"
          name="sample"
          validation="required"
          :value="value"
          error-behavior="live"
          @input="valueChanged"
        />
        <span class="color-title ml-4">{{ title }}</span>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="remove-color" @click="valueChanged(`${colorsHtml.white}`)">
          <font-awesome-icon class="fa-times" icon="custom-times" />
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";

import { library } from "@fortawesome/fontawesome-svg-core";
import { customTimes } from "@/assets/icons";
library.add(customTimes);
import colorsHtml from "/colors.config.json";
export default {
  name: "ColorPickerCard",
  components: {
    BaseCard,
  },
  data() {
    return {
      colorsHtml: colorsHtml,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: `${colorsHtml.white}`,
    },
  },
  methods: {
    valueChanged(value) {
      this.$emit("changed", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-picker {
  .remove-color {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: $background_3;
    border-radius: 50%;
    cursor: pointer;

    svg {
      font-size: 1.5rem;
      color: $light_text;
    }
  }
}
</style>
